import React from 'react';
import './modal.css';

const VideoModal = ({channel, isOpen, videoId, onClose}) => {
    return(
        <div className="modal-video-wrapper">
            Not supported now.
        </div>
    )
}

export default VideoModal;